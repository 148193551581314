import { PositionReadableTimings } from '@gourmate/api'
import { dayOfTheWeek, dayOfTheWeekShort, getReadableWeekday } from 'shared-utils'

type GetReadableWeekdaysOptions = {
  isShortNames?: boolean
  isGrouped?: boolean // implement later like: M - Sun
}

export const getReadableWeekdays = (
  weekdays: PositionReadableTimings['weekdays'],
  options?: GetReadableWeekdaysOptions
) => {
  const { isShortNames, isGrouped } = options || {}

  if (isGrouped && weekdays.length > 2) {
    const groups: number[][] = []
    let currentGroupIndex = 0

    weekdays.forEach((day, index) => {
      if (index === 0) {
        groups.push([day])
        return
      }

      const prev = weekdays[index - 1]

      if (day - 1 === prev) {
        groups[currentGroupIndex].push(day)
      } else {
        currentGroupIndex += 1
        groups.push([day])
      }
    })

    return groups
      .reduce((acc, group) => {
        const str =
          group.length > 2
            ? `${getReadableWeekday(group[0], isShortNames)} - ${getReadableWeekday(group[group.length - 1], isShortNames)}`
            : group.map((day) => getReadableWeekday(day, isShortNames)).join(', ')

        acc.push(str)

        return acc
      }, [] as string[])
      .join(', ')
  }

  return weekdays
    .map((d) => {
      return (isShortNames ? dayOfTheWeekShort : dayOfTheWeek)[d as 1 | 2 | 3 | 4 | 5 | 6 | 7]
    })
    .join(', ')
}
