import { Box, BoxProps, useColorMode } from '@chakra-ui/react'

type MarkerShadowProps = BoxProps

export const MarkerShadow = (props: MarkerShadowProps) => {
  const { colorMode } = useColorMode()

  return (
    <Box
      backgroundColor={colorMode === 'light' ? '#cfd0d0' : '#6d6e6e'}
      borderRadius="50%"
      className="dot"
      height="2px"
      left="calc(50% - 1px)"
      position="absolute"
      shadow="0 0 7px 3px #a5a6a7"
      top="calc(50% - 1px)"
      transform="rotateX(54deg)"
      transformOrigin="center"
      w="2px"
      {...props}
    />
  )
}
