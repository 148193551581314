import { FC, ReactNode, useEffect, useState } from 'react'
import { Button, Skeleton, Spacer, VStack } from '@chakra-ui/react'
import { CustomMap } from '#/src/app/(application)/_components/CustomMap'
import { useBottomSheet } from 'ui'
import { useDebouncedCallback } from 'ui/hooks'
import { useGeocoder } from '#/src/hooks/useGeocoder'
import { BaseCoordinates, BasePlace } from '#/src/types'
import { isUndefined } from 'shared-utils'
import { GrLocation } from 'react-icons/gr'
import { InfoListItem } from '#/src/app/(application)/app/_components/InfoList'

interface AddressConfirmationMapProps {
  selectedPlace: BasePlace
  onSubmit?: (submittedPlace: BasePlace) => void
  renderHeader?: () => ReactNode
}

export const AddressConfirmationMap: FC<AddressConfirmationMapProps> = ({
  onSubmit,
  selectedPlace,
  renderHeader
}) => {
  const { setDisableDrag } = useBottomSheet()
  const [center, setCenter] = useState<Partial<BaseCoordinates>>({
    lat: selectedPlace?.lat,
    lng: selectedPlace?.lng
  })

  const [calculatedAddress, setCalculatedAddress] = useState<BasePlace>()
  const [isLoadingAddress, setIsLoadingAddress] = useState(false)

  const { geoCodeLatLng, getParsedAddressFromResults } = useGeocoder()

  const calculateAddress = useDebouncedCallback(() => {
    if (isUndefined(center.lng) || isUndefined(center.lat)) {
      return
    }

    setIsLoadingAddress(true)

    Promise.resolve()
      .then(() => geoCodeLatLng(center.lat!, center.lng!))
      .then((geocodingResult) => {
        if (!geocodingResult?.length) {
          return
        }

        const parsedResult = getParsedAddressFromResults(geocodingResult)

        if (!parsedResult) {
          return
        }

        const { address, formattedAddress, result } = parsedResult

        setCalculatedAddress({
          formattedAddress,
          address,
          id: result.place_id,
          lat: center.lat,
          lng: center.lng
        })
      })
      .finally(() => {
        setIsLoadingAddress(false)
      })
  }, 500)

  useEffect(() => {
    setIsLoadingAddress(true)
    calculateAddress()
  }, [calculateAddress, center])

  const handleSave = () => {
    if (!calculatedAddress) {
      return
    }

    onSubmit?.(calculatedAddress)
  }

  return (
    <VStack alignItems="flex-start" flex={1} gap={6} w="full">
      {!!renderHeader && renderHeader()}

      <CustomMap
        isMarkerStickToCenter
        lat={center.lat}
        lng={center.lng}
        onCenterChanged={(d) => {
          setCenter({
            lat: d.map.getCenter()?.lat(),
            lng: d.map.getCenter()?.lng()
          })
        }}
        onDragend={() => {
          setDisableDrag(false)
        }}
        onDragstart={() => {
          setDisableDrag(true)
        }}
      />

      <Spacer flex={1} />

      <VStack gap={4} w="100%">
        <InfoListItem
          alignItems="center"
          icon={<GrLocation />}
          opacity={isLoadingAddress ? 0.7 : 1}
          subTitle={
            <Skeleton isLoaded={!!calculatedAddress?.address}>
              {`${calculatedAddress?.address?.postalCode} ${calculatedAddress?.address?.city}`}
            </Skeleton>
          }
          title={
            <Skeleton
              isLoaded={!!calculatedAddress?.address}
              mb={!!calculatedAddress?.address ? 0 : 1}>
              {`${calculatedAddress?.address?.streetName} ${calculatedAddress?.address?.streetNumber}`}
            </Skeleton>
          }
          titleProps={{ fontWeight: 'semibold' }}
          w="100%"
        />

        <Button
          isDisabled={!calculatedAddress}
          isLoading={isLoadingAddress}
          onClick={handleSave}
          w="full">
          Confirm address
        </Button>
      </VStack>
    </VStack>
  )
}

export default AddressConfirmationMap
