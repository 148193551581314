import type { FC, RefObject } from 'react'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import Popover from 'ui/common/Popover'
import {
  Place,
  PlaceAutocompleteFieldProps,
  PlaceAutocompleteInput
} from '#/src/app/(application)/_components/autocomplete/PlaceAutocompleteInput'
import AutoCompleteResults from '#/src/app/(application)/_components/autocomplete/AutoCompleteResults'
import { ReducerActionType, useSetReset } from 'ui/hooks'
import CustomMap from '#/src/app/(application)/_components/CustomMap'
import { Box, useDisclosure, useMergeRefs, VStack } from '@chakra-ui/react'
import { DEFAULT_LOCATION } from '#/src/constants/locations'
import { useSelector } from 'react-redux'
import { selectDeviceLocationState } from '#/src/state/device-loc'
import { usePlace } from '#/src/hooks/usePlace'
import { useBottomSheet } from 'ui'

interface AddressSearchProps {
  onUpdate: (place: Place & { lat: number; lng: number }) => void
  hideMap?: boolean
  inputProps?: Omit<PlaceAutocompleteFieldProps, 'onUpdate' | 'origin'>
  inputRef?: RefObject<HTMLInputElement>
}

export const AddressSearch: FC<AddressSearchProps> = ({
  onUpdate,
  hideMap,
  inputProps,
  inputRef: inputRefProp
}) => {
  const { setDisableDrag } = useBottomSheet()
  const { defaultLoc } = useSelector(selectDeviceLocationState)
  const [places, setPlaces] = useState<Place[]>()
  const localInputRef = useRef<HTMLInputElement | null>(null)
  const { getNearbyPlace, divRef } = usePlace()
  const inputRef = useMergeRefs(inputRefProp, localInputRef)

  const getPlace = useCallback(
    async (lat: number, lng: number) => {
      const googlePlace = await getNearbyPlace(lat, lng)

      return {
        formattedAddress: `${googlePlace?.name}`,
        id: googlePlace?.place_id!,
        lat,
        lng
      }
    },
    [getNearbyPlace]
  )

  const [coordinates, dispatchCoordinates] = useSetReset({
    lat: defaultLoc?.lat || DEFAULT_LOCATION.lat,
    lng: defaultLoc?.lng || DEFAULT_LOCATION.lng
  })

  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    if (places?.length) {
      !isOpen && onOpen()
    } else {
      isOpen && onClose()
    }
  }, [isOpen, onClose, onOpen, places?.length])

  return (
    <>
      <Box ref={divRef} />

      <VStack spacing={4} w="full">
        <Popover
          contentProps={{
            maxWidth: 'calc(100vw - var(--chakra-space-12))',
            width: 'auto',
            minW: 'var(--chakra-sizes-xs)',
            sx: {
              '.chakra-popover__body': {
                p: 0
              }
            }
          }}
          initialFocusRef={localInputRef}
          placement="bottom-start"
          {...{ isOpen }}
          target={
            <PlaceAutocompleteInput
              onUpdate={setPlaces}
              origin={{ lat: coordinates.lat, lng: coordinates.lng }}
              ref={inputRef}
              {...inputProps}
            />
          }
          title="">
          <AutoCompleteResults
            onSelectItem={async (place) => {
              if (!place.lat || !place.lng) return
              dispatchCoordinates({
                payload: { lng: place.lng, lat: place.lat },
                type: ReducerActionType.Set
              })
              setPlaces(undefined)

              onUpdate({ ...place, lat: place.lat!, lng: place.lng! })

              onClose()
            }}
            places={places}
            zIndex="docked"
          />
        </Popover>
        {!hideMap ? (
          <CustomMap
            lat={coordinates.lat}
            lng={coordinates.lng}
            onDragend={(d) => {
              setDisableDrag(false)
              const lat = d.map.getCenter()?.lat()
              const lng = d.map.getCenter()?.lng()
              if (!lat || !lng) return
              dispatchCoordinates({ payload: { lng, lat }, type: ReducerActionType.Set })
              getPlace(lat, lng).then((place) => {
                onUpdate(place)
              })
            }}
            onDragstart={() => {
              setDisableDrag(true)
            }}
            wrapperProps={{ h: '35vh', w: '90%' }}
            zoom={15}
          />
        ) : null}
      </VStack>
    </>
  )
}

export default AddressSearch
