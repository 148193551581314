// noinspection JSUnresolvedReference

import { useCallback, useEffect, useRef, useState } from 'react'
import { useMapsLibrary } from '@vis.gl/react-google-maps'
import { Box, forwardRef, InputProps, useMergeRefs } from '@chakra-ui/react'
import DebounceInput from 'ui/common/DebounceInput'
import { BasePlace } from '#/src/types'

export type PlaceAutocompleteFieldProps = InputProps & {
  onUpdate: (places: Place[]) => void
  origin?: {
    lat: number
    lng: number
  }
}

export interface Place extends BasePlace {}

export const PlaceAutocompleteInput = forwardRef<PlaceAutocompleteFieldProps, 'input'>(
  ({ onUpdate, origin, ...props }: PlaceAutocompleteFieldProps, ref) => {
    const inputRef = useRef<HTMLInputElement | null>(null)
    const mergedInputRef = useMergeRefs(inputRef, ref)

    const divRef = useRef<HTMLDivElement | null>(null)
    const placesLib = useMapsLibrary('places')
    const [autocomplete, setAutocomplete] = useState<google.maps.places.AutocompleteService | null>(
      null
    )
    const [places, setPlaces] = useState<Place[]>([])

    const convertPredictionIntoPlace = useCallback(
      (prediction: google.maps.places.AutocompletePrediction) => {
        if (!placesLib || !divRef.current) return null
        const placesService = new placesLib.PlacesService(divRef.current)
        return new Promise((resolve, reject) => {
          placesService.getDetails({ placeId: prediction.place_id }, (res) => {
            if (!res) {
              reject('No place found')
              return
            }
            resolve(res)
          })
        }) as Promise<google.maps.places.PlaceResult>
      },
      [placesLib]
    )

    useEffect(() => {
      if (!placesLib) return
      if (inputRef?.current && !autocomplete) {
        const autocompleteInstance = new placesLib.AutocompleteService()
        if (!autocompleteInstance) return
        setAutocomplete(autocompleteInstance)
      }
    }, [autocomplete, inputRef, placesLib])

    const fetchPlaces = useCallback(
      async (value: string) => {
        if (!autocomplete) return

        if (!value) {
          setPlaces([])
          return
        }

        const res = await autocomplete.getPlacePredictions({
          input: value,
          origin
        })

        const p: Place[] = []

        for (let i = 0; i < res.predictions.length; i++) {
          const pred = res.predictions[i]
          const place = await convertPredictionIntoPlace(pred)

          const distance = pred.distance_meters
            ? `${(pred.distance_meters / 1000).toFixed(2)} km`
            : undefined

          p.push({
            id: pred.place_id,
            htmlStr: place?.adr_address,
            formattedAddress: place?.formatted_address,
            lat: place?.geometry?.location?.lat(),
            lng: place?.geometry?.location?.lng(),
            distance
          })
        }

        setPlaces(p)
      },
      [autocomplete, convertPredictionIntoPlace, origin]
    )

    const handleInputChange = useCallback(
      async (value: string) => {
        if (!value) {
          setPlaces([])
        }

        await fetchPlaces(value)
      },
      [fetchPlaces]
    )

    useEffect(() => {
      onUpdate(places)
    }, [onUpdate, places])

    return (
      <>
        <DebounceInput
          fontSize="16px"
          onDebounce={handleInputChange}
          placeholder="Search an address..."
          ref={mergedInputRef}
          {...props}
        />
        <Box ref={divRef} />
      </>
    )
  }
)
