import { useMapsLibrary } from '@vis.gl/react-google-maps'
import { useCallback } from 'react'
import { BaseAddress } from '#/src/types'

const dictAddressTypes: Record<keyof BaseAddress, string[]> = {
  streetNumber: ['street_number'],
  streetName: ['street_address', 'route'],
  city: [
    'postal_town',
    'sublocality',
    'sublocality_level_1',
    'sublocality_level_2',
    'sublocality_level_3',
    'sublocality_level_4'
  ],
  region: [
    'administrative_area_level_1',
    'administrative_area_level_2',
    'administrative_area_level_3',
    'administrative_area_level_4',
    'administrative_area_level_5'
  ],
  postalCode: ['postal_code'],
  country: ['country']
}

const getParsedAddressFromResults = (results: google.maps.GeocoderResult[]) => {
  if (!results.length) {
    return
  }

  const streetResult = results.find((res) =>
    res.types.some((type) => ['street_address', 'premise'].includes(type))
  )

  if (!streetResult) {
    return
  }

  const addressObj: BaseAddress = {}

  Object.keys(dictAddressTypes).forEach((k) => {
    const key = k as keyof BaseAddress

    const foundedComponent = streetResult.address_components.find((component) => {
      return component.types.some((type) => dictAddressTypes[key]?.includes(type))
    })

    if (foundedComponent) {
      addressObj[key] = foundedComponent.long_name
    }
  })

  const { streetName = '', streetNumber, city, postalCode } = addressObj

  return {
    address: addressObj,
    formattedAddress: `${streetName} ${streetNumber}, ${postalCode} ${city}`,
    result: streetResult
  }
}

export const useGeocoder = () => {
  const geoCoderLib = useMapsLibrary('geocoding')
  // const [place, setPlace] = useState('')

  const geoCodeLatLng = useCallback(
    (lat: number, lng: number) => {
      if (!geoCoderLib) return null
      const location = new google.maps.LatLng(lat, lng)

      const geocoder = new geoCoderLib.Geocoder()

      const request = {
        location
      }

      return new Promise((resolve, reject) => {
        geocoder.geocode(request, (res) => {
          if (!res || !res.length) {
            reject('No place found')
            return
          }

          resolve(res)
        })
      }) as Promise<google.maps.GeocoderResult[]>
    },
    [geoCoderLib]
  )

  return { geoCodeLatLng, getParsedAddressFromResults }
}
