import type { FC, ReactElement } from 'react'
import type { PopoverContentProps, PopoverProps } from '@chakra-ui/react'
import {
  Popover as ChakraPopover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger
} from '@chakra-ui/react'

interface IPopover extends PopoverProps {
  title: string
  target: ReactElement
  contentProps?: PopoverContentProps
  children: any
}

const Popover: FC<IPopover> = ({ title, target, children, contentProps, ...props }) => {
  return (
    <ChakraPopover
      closeOnBlur
      isLazy
      placement="bottom-end"
      returnFocusOnClose={false}
      variant="responsive"
      {...props}>
      <PopoverTrigger>{target}</PopoverTrigger>
      <PopoverContent color="text.default" {...contentProps}>
        {title ? <PopoverHeader fontWeight="semibold">{title}</PopoverHeader> : null}
        <PopoverArrow />
        {title ? <PopoverCloseButton _focus={{ outline: 'none' }} aria-label="close" /> : null}
        <PopoverBody>{children}</PopoverBody>
      </PopoverContent>
    </ChakraPopover>
  )
}

export default Popover
