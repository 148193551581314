import { FC, useMemo } from 'react'
import { Box, BoxProps, Icon, useColorMode } from '@chakra-ui/react'
import { ControlPosition, Map, MapControl, MapProps, Marker } from '@vis.gl/react-google-maps'
import { BaseCoordinates } from '#/src/types'
import { isUndefined } from 'shared-utils'
import { MdLocationOn } from 'react-icons/md'
import { MarkerShadow } from './MarkerShadow'
import { mapStyleLight } from './map-style-light'
import { mapStyleDark } from './map-style-dark'
import mapMarkerIcon from './map-marker-icon.svg'

export interface CustomMapProps extends MapProps {
  lat?: number
  lng?: number
  isMarkerStickToCenter?: boolean
  wrapperProps?: BoxProps
}

export const CustomMap: FC<CustomMapProps> = ({
  isMarkerStickToCenter,
  lat,
  lng,
  wrapperProps,
  ...props
}) => {
  const { colorMode } = useColorMode()
  const mapStyle = colorMode === 'light' ? mapStyleLight : mapStyleDark
  const center: BaseCoordinates | undefined =
    isUndefined(lat) || isUndefined(lng) ? undefined : { lat: lat!, lng: lng! }

  const markerIconParams = useMemo(
    () => ({
      url: mapMarkerIcon.src,
      scaledSize: new google.maps.Size(50, 50),
      origin: new google.maps.Point(0, 0)
    }),
    []
  )

  return (
    <Box
      h="50vh"
      overflow="hidden"
      position="relative"
      rounded="md"
      shadow="sm"
      w="full"
      zIndex={0}
      {...wrapperProps}>
      <Map
        center={center}
        disableDefaultUI
        disableDoubleClickZoom
        gestureHandling="greedy"
        styles={mapStyle}
        zoom={18}
        {...props}>
        {isMarkerStickToCenter ? (
          <MapControl position={ControlPosition.CENTER}>
            <Icon
              as={MdLocationOn}
              color="primary"
              fontSize="50px"
              opacity={1}
              position="relative"
              top="-23px"
            />
            <MarkerShadow />
          </MapControl>
        ) : (
          <Marker icon={markerIconParams} position={center} />
        )}
      </Map>
    </Box>
  )
}

export default CustomMap
