import { Box, Center, HStack, StackProps, TextProps, VStack } from '@chakra-ui/react'
import { ReactElement, ReactNode } from 'react'

export type InfoListItemProps = Omit<StackProps, 'title'> & {
  icon: ReactElement
  title: ReactNode
  subTitle?: ReactNode
  titleProps?: TextProps
}

export const InfoListItem = ({
  icon,
  title,
  subTitle,
  titleProps,
  ...props
}: InfoListItemProps) => {
  return (
    <HStack alignItems="flex-start" className="info-list-item" gap="4" {...props}>
      <Center alignItems="flex-start" className="info-list-item__icon" fontSize="2xl">
        {icon}
      </Center>

      <VStack alignItems="flex-start" spacing={0}>
        <Box fontSize="md" {...titleProps}>
          {title}
        </Box>

        {!!subTitle && (
          <Box color="text.secondary" fontSize="sm">
            {subTitle}
          </Box>
        )}
      </VStack>
    </HStack>
  )
}
