import {
  AccountType,
  notCancellableOrderStatus,
  OfferDTO,
  OrderDTO,
  OfferStatus
} from '@gourmate/api'

export const getAccountTypeName = (currentAccountType: AccountType) => {
  const dict: Record<AccountType, string> = {
    [AccountType.Member]: 'Member',
    [AccountType.Supplier]: 'Chef',
    [AccountType.Unrecognized]: ''
  }

  return dict[currentAccountType]
}

export const createDateFromString = (date?: string): Date | undefined => {
  if (!date) {
    return
  }

  const parsedDate: number = Date.parse(date)

  return Number.isNaN(parsedDate) ? void 0 : new Date(parsedDate)
}

const isDateInFuture = (date?: string | null | number | Date) => {
  if (typeof date === 'undefined' || date === null) {
    return false
  }

  let parsedDate: Date | undefined

  if (typeof date === 'string') {
    parsedDate = createDateFromString(date)

    if (!parsedDate) {
      return false
    }
  } else {
    parsedDate = new Date(date)
  }

  return parsedDate.getTime() - Date.now() > 0
}

export const isOrderExpirable = (order: OrderDTO) => {
  return isDateInFuture(order.expiresAt) && !notCancellableOrderStatus.includes(order.status)
}

export const isOfferExpirable = (offer: OfferDTO) => {
  return offer.status !== OfferStatus.Expired && isDateInFuture(offer.expiresAt)
}
