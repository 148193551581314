import AddressSearch from '#/src/app/(application)/_components/AddressSearch'
import { useDeviceCurrentPosition } from 'ui/hooks'
import {
  Button,
  Flex,
  Heading,
  HStack,
  Icon,
  Spinner,
  Text,
  useDisclosure,
  useUpdateEffect,
  VStack
} from '@chakra-ui/react'
import { BiSolidError } from 'react-icons/bi'
import { TiLocationArrow } from 'react-icons/ti'
import React, { useRef, useState } from 'react'
import { BasePlace } from '#/src/types'
import { BottomSheet } from 'ui'
import AddressConfirmationMap from './AddressConfirmationMap'
import { GrLocation } from 'react-icons/gr'
import { InfoListItem } from '#/src/app/(application)/app/_components/InfoList'

type AddressEditableProps = {
  onUpdate?: (place: BasePlace) => void
  onConfirm?: (place: BasePlace) => void
}

export const AddressEditable = ({ onUpdate, onConfirm }: AddressEditableProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const { isLoading, getCurrentPosition, error } = useDeviceCurrentPosition()
  const [selectedPlace, setSelectedPlace] = useState<BasePlace>()
  const [confirmedPlace, setConfirmedPlace] = useState<BasePlace>()
  const [isEdit, setIsEdit] = useState(true)
  const inputRef = useRef<HTMLInputElement>(null)

  useUpdateEffect(() => {
    if (isEdit) {
      inputRef.current?.focus()
    }
  }, [isEdit])

  const handleCurrenLocation = () => {
    if (isLoading) {
      return
    }

    getCurrentPosition()
      .then((position) => {
        const newSelectedPlace = {
          id: 'currentLocation',
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }
        setSelectedPlace(newSelectedPlace)
        onOpen()
        onUpdate?.(newSelectedPlace)
      })
      .catch(() => void 0)
  }

  return (
    <VStack alignItems="stretch" gap={0} w="full">
      {isEdit ? (
        <>
          <AddressSearch
            hideMap
            inputProps={{
              defaultValue:
                confirmedPlace &&
                `${confirmedPlace?.address?.streetName} ${confirmedPlace?.address?.streetNumber}`
            }}
            inputRef={inputRef}
            onUpdate={(payload) => {
              setSelectedPlace(payload)
              onOpen()
              onUpdate?.(payload)
            }}
          />

          <HStack
            onClick={handleCurrenLocation}
            opacity={isLoading ? 0.7 : 1}
            p={0}
            spacing={1}
            w="full"
            {...(error ? { color: 'red' } : {})}>
            <Flex alignItems="center" flex={0} justifyContent="center" minW={4}>
              {isLoading ? (
                <Spinner size="xs" />
              ) : (
                <Icon as={error ? BiSolidError : TiLocationArrow} />
              )}
            </Flex>

            <Text flex={1} fontSize="sm" textDecor="underline" userSelect="none">
              Device location
            </Text>
          </HStack>

          <BottomSheet
            isOpen={isOpen}
            onClose={onClose}
            sheetProps={{
              detent: 'content-height',
              snapPoints: undefined
            }}
            target={null}>
            <VStack px={6}>
              <AddressConfirmationMap
                onSubmit={(newConfirmedPlace) => {
                  setConfirmedPlace(newConfirmedPlace)
                  onClose()
                  setIsEdit(false)
                  onConfirm?.(newConfirmedPlace)
                }}
                renderHeader={() => <Heading fontSize="xl">Address</Heading>}
                selectedPlace={selectedPlace!}
              />
            </VStack>
          </BottomSheet>
        </>
      ) : (
        <HStack justifyContent="space-between">
          <InfoListItem
            alignItems="center"
            icon={<GrLocation />}
            subTitle={`${confirmedPlace?.address?.postalCode} ${confirmedPlace?.address?.city}`}
            title={`${confirmedPlace?.address?.streetName} ${confirmedPlace?.address?.streetNumber}`}
            w="100%"
          />

          <Button
            onClick={() => {
              setIsEdit(true)
            }}
            variant="link">
            Edit
          </Button>
        </HStack>
      )}
    </VStack>
  )
}
