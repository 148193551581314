import { useMapsLibrary } from '@vis.gl/react-google-maps'
import { useCallback, useEffect, useRef, useState } from 'react'

export const usePlace = (lat?: number, lng?: number) => {
  const placesLib = useMapsLibrary('places')
  const divRef = useRef<HTMLDivElement | null>(null)
  const [place, setPlace] = useState('')

  const getNearbyPlace = useCallback(
    (lat: number, lng: number) => {
      if (!placesLib || !divRef.current) return null
      const placesService = new placesLib.PlacesService(divRef.current)
      const location = new google.maps.LatLng(lat, lng)

      const request = {
        location,
        radius: 150,
        type: 'point_of_interest'
      }

      return new Promise((resolve, reject) => {
        placesService.nearbySearch(request, (res) => {
          if (!res || !res.length) {
            reject('No place found')
            return
          }
          resolve(res[0])
        })
      }) as google.maps.places.PlaceResult | PromiseLike<google.maps.places.PlaceResult>
    },
    [placesLib]
  )

  useEffect(() => {
    if (!lat || !lng) return
    ;(async () => {
      try {
        const p = await getNearbyPlace(lat, lng)
        setPlace(p?.name || '')
      } catch {
        // empty
      }
    })()
  }, [getNearbyPlace, lat, lng])

  return { place, divRef, getNearbyPlace }
}
